import api from './../libs/api';

export default {


get: () => {
  return new Promise((resolve, reject) => {
      api()
          .get(`/users`)
          .then(({ status, data }) => {
              if (status === 200) resolve(data);
              throw new Error(`Failed with status: ${status}`);
          })
          .catch(error => reject(error));
  });
}

}