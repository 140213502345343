import React, { useState, useEffect } from "react";
import { Table, Input, Space, Avatar, Tag, Modal, Button, Form, message, Menu, Dropdown } from "antd"; // Add Menu and Dropdown here
import { SearchOutlined, UserOutlined, MoreOutlined, PlusOutlined, MailOutlined, LockOutlined } from "@ant-design/icons"; // Add PlusOutlined here
import PageLayout from "../../components/layout/Layout";
import user from "../../services/user"; 
import env from '../../env';
import ProfileForm from '../../components/profile/ProfileForm';
import profile from "../../services/profile"; // Add this line
import auth from "../../services/auth"; // Add this line

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false); // Add this line
  const [selectedUser, setSelectedUser] = useState(null);
  const [form] = Form.useForm();
  const [registerForm] = Form.useForm(); // Add this line
  // Fetch Users from API
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await user.get(); 
      if (response.data) {
        setUsers(response.data);
      }
    } catch (error) {
      message.error(error.response.data.message);
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleMenuClick = async (key, record) => {
    try {
      setLoading(true);
      switch (key) {
        case "viewProfile":
          showModal(record);
          break;
        case "toggleLock":
          const response = await auth.toggleLockUser(record.id);
          message.success(response.message);
          fetchUsers();
          break;
        case "deleteUser":
          Modal.confirm({
            title: 'Are you sure you want to delete this user?',
            content: 'This action cannot be undone. All the associated data will be removed',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
              await auth.deleteUser(record.id);
              fetchUsers();
              message.success("User deleted successfully");
            },
          });
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error processing request:", error);
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const menu = (record) => (
    <Menu onClick={({ key }) => handleMenuClick(key, record)}>
      <Menu.Item key="viewProfile">Edit Profile</Menu.Item>
      {console.log(record)}
      {record.roles[0].name !== "admin" && (
        <>
          <Menu.Item key="toggleLock">{record.is_locked ? "Unlock User" : "Lock User"}</Menu.Item>
          <Menu.Item key="deleteUser">Delete User</Menu.Item>
        </>
      )}
    </Menu>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <>
         <Avatar src={env.BACKEND_STORAGE_URL+"/"+record.profile_picture} alt={record.name} /> {record.name}
        </>
      ),
    },
    {
      title: 'Last',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Status',
      key: 'status',
      render: (text, record) => (
       // console.log(record),
        <Tag color={record.is_locked ? "red" : "green"}>
          {record.is_locked ? "Locked" : "Unlocked"}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Dropdown overlay={menu(record)} trigger={['click']}>
          <Button type="link" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredUsers = users.filter(user =>
    (user.name && user.name.toLowerCase().includes(searchText.toLowerCase())) ||
    (user.last_name && user.last_name.toLowerCase().includes(searchText.toLowerCase())) ||
    (user.email && user.email.toLowerCase().includes(searchText.toLowerCase())) ||
    (user.phone && user.phone.toLowerCase().includes(searchText.toLowerCase()))
  );

  const showModal = (user) => {
    console.log("selectedUser", user);
    setSelectedUser(user);
    form.setFieldsValue(user);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedUser(null);
  };

  const handleFinish = async (values) => {
    try {
      setLoading(true);
      const response = await profile.update( { ...values, id: selectedUser.id });
      if (response.data) {
        fetchUsers();
        setIsModalVisible(false);
        message.success("Profile updated successfully");
      }
    } catch (error) {
      message.error(error.response.data.message);
      console.error("Error updating user profile:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddUser = async (values) => {
    try {
      //console.log('values', values);
      setLoading(true);
      await auth.register(values);
      message.success('User added successfully!');
      setIsAddUserModalVisible(false);
      fetchUsers();
    } catch (error) {
      console.error('Add User Error:', error);
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageLayout style={{ padding: "20px" }}>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsAddUserModalVisible(true)}>
          Add User
        </Button>
        <Input
          placeholder="Search users"
          value={searchText}
          onChange={handleSearch}
          prefix={<SearchOutlined />}
          allowClear
        />
        <Table
          size="small"
          columns={columns}
          dataSource={filteredUsers}
          rowKey="id"
          loading={loading}
          pagination={{ pageSize: 10 }}
        />
      </Space>
      <Modal
        title="User Profile"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {selectedUser && (
          <ProfileForm
            form={form}
            handleFinish={handleFinish}
            btnLoading={loading}
            changePassword={false}
            toggleChangePassword={() => {}}
          />
        )}
      </Modal>
      <Modal
        title="Add User"
        visible={isAddUserModalVisible}
        onCancel={() => setIsAddUserModalVisible(false)}
        footer={null}
      >
        <Form
          name="register_form"
          className="register-form"
          onFinish={handleAddUser}
          form={registerForm}
        >
          <h2 className="register-title">Register Now</h2>
          
          <Form.Item
            name="name"
            rules={[
              { required: true, message: 'Please input your first name!' },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="Name"
            />
          </Form.Item>

          <Form.Item
            name="last_name"
            rules={[
              { required: true, message: 'Please input your last name!' },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="Last Name"
            />
          </Form.Item>
          
          <Form.Item
            name="email"
            rules={[
              { type: 'email', message: 'The input is not a valid email address!' },
              { required: true, message: 'Please input your email!' },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder="Email"
            />
          </Form.Item>
          
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
              { min: 6, message: 'Password must be at least 6 characters long!' },
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          </Form.Item>
          
          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            hasFeedback
            rules={[
              { required: true, message: 'Please confirm your password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The two passwords do not match!')
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Confirm Password"
            />
          </Form.Item>
       
          
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="register-form-button"
            >
              Register
            </Button>
          </Form.Item>

        </Form>
      </Modal>
    </PageLayout>
  );
};

export default Users;