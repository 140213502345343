import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider, theme } from 'antd';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { store } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            borderRadius: 2,
            // colorBgContainer: '#f3f6fe',
            // colorPrimary: '#00b96b',
          },
          algorithm: theme.compactAlgorithm, // Apply the compact algorithm here
        }}
      >
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);
