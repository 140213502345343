const flag = "production";

const env = {
  production: {
    BACKEND_URL: "https://www.nexiscode.com/",
    BACKEND_STORAGE_URL: "https://www.nexiscode.com/storage",
    API_URL: "https://www.nexiscode.com/api",
    FRONTEND_URL: "https://insurancepolicy-xi.vercel.app/",
  },
  staging: {
    BACKEND_URL: "http://127.0.0.1:8000",
    BACKEND_STORAGE_URL: "http://127.0.0.1:8000/storage",
    API_URL: "http://127.0.0.1:8000/api",
    FRONTEND_URL: "https://qm-frontend.vercel.app",
  },
};

export default {
  ...env[flag],
  flag: flag,
};
