export const calculateCategoryTotals = (allValues) => {
  let totalValue1 = 0;
  let totalValue2 = 0;
  let combinedTotal = 0;
  let categoryTotalsValue1 = {};
  let categoryTotalsValue2 = {};

  Object.keys(allValues).forEach((category) => {
    const categoryValues = allValues[category] || [];
    if (Array.isArray(categoryValues)) {
      const categoryTotal1 = categoryValues.reduce((sum, item) => sum + (Number(item.value_1) || 0), 0); 
      const categoryTotal2 = categoryValues.reduce((sum, item) => sum + (Number(item.value_2) || 0), 0); 
      categoryTotalsValue1[category] = categoryTotal1;
      categoryTotalsValue2[category] = categoryTotal2; 

      totalValue1 += categoryTotal1;
      totalValue2 += categoryTotal2; 
    }
  });
  combinedTotal = totalValue1 + totalValue2; 
  return { totalValue1, totalValue2, combinedTotal, categoryTotalsValue1, categoryTotalsValue2 };
};

export const calculateTotalAnnual = (categories, key) => {
  return categories.reduce((total, category) => total + category[key], 0);
};

export const calculateTotalMonthly = (categories, key) => {
  return calculateTotalAnnual(categories, key) / 12;
};

export const formatCurrency = (value) => {
  if (!value) return "$0.00"; 
  const formattedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
  return formattedValue;
};


export const getTotalStyle = (value1, value2) => {
  if (value1 > value2) {
    return { backgroundColor: '#ccff99', textAlign: 'center',  }; // Green background
  } else if (value2 > value1) {
    return { backgroundColor: '#ffff99', textAlign: 'center' }; // Yellow background
  }  else if (value2 === value1) {
    return { backgroundColor: '#ccff99', textAlign: 'center' }; // Green background
  }
  return {};
};

export const  reverseGetTotalStyle = (value1, value2) => {
  if (value1 > value2) {
    return { backgroundColor: '#ffff99', textAlign: 'center' };
  } else if (value2 > value1) {
    return { backgroundColor: '#ccff99', textAlign: 'center'};
  }
  return {};
};
