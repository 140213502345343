
import api from '../libs/api';

export default {

  getAll: () => {
    return new Promise((resolve, reject) => {
        api()
            .get(`/policies`)
            .then(({ status, data }) => {
                if (status === 200) resolve(data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

  

  toggleMarkTemplate: (id) => {
    return new Promise((resolve, reject) => {
        api()
            .put(`/policy/${id}/toggle-template`)
            .then(({ status, data }) => {
                if (status === 200) resolve(data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

  getById: (id) => {
    return new Promise((resolve, reject) => {
        api()
        .get(`/policy/${id}`)
        .then(({ status, data }) => {
                if (status === 200) resolve(data.data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

  history: (id) => {
    return new Promise((resolve, reject) => {
        api()
        .get(`/policy/${id}/history`)
        .then(({ status, data }) => {
                if (status === 200) resolve(data.data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

  post: (data) => {
    return new Promise((resolve, reject) => {
        api()
            .post(`/policy`, data)
            .then(({ status, data }) => {
                if (status === 201) resolve(data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },


  update: (id, data) => {
    return new Promise((resolve, reject) => {
        api()
            .put(`/policy/${id}`, data)
            .then(({ status, data }) => {
                if (status === 200) resolve(data.data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },


  delete: (id) => {
    return new Promise((resolve, reject) => {
        api()
            .delete(`/policy/${id}`)
            .then(({ status, data }) => {
                if (status === 200) resolve(data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },
 

}