import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Layout, Menu, Form, Input, Button, Modal, Select, Row, Col, Collapse, message, Spin, Table, Skeleton, Affix, Card, Badge, Space } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, MinusCircleOutlined, FileTextOutlined, MenuOutlined } from '@ant-design/icons';
import policy from '../../services/policy';
import template from '../../services/template';
import PageLayout from '../../components/layout/Layout';
import { calculatePercentageDifference } from '../../utils/pdfUtils';
import { formatCurrency, getTotalStyle } from '../../utils/helpers';
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';

const { Content, Sider } = Layout;
const { Panel } = Collapse;

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
));

const SortableItem = SortableElement(({ sub, catIndex, subIndex, handleSubcategoryChange, handleDeleteSubcategory }) => (
  <Row key={subIndex} className='subcategory-row' gutter={16} align="middle" style={{ marginBottom: '10px' }}>
    <Col span={1}>
      <DragHandle />
    </Col>
    <Col span={5}>
      <Input
        value={sub.name}
        onChange={e => handleSubcategoryChange(catIndex, subIndex, 'name', e.target.value)}
      />
    </Col>
    <Col span={6}>
      <Input
        prefix="$"
        value={sub.value_1}
        onChange={e => handleSubcategoryChange(catIndex, subIndex, 'value_1', e.target.value)}
        style={{
          border: 'none',
          borderBottom: '1px dashed gray'
        }}
      />
    </Col>
    <Col span={6}>
      <Input
        prefix="$"
        value={sub.value_2}
        onChange={e => handleSubcategoryChange(catIndex, subIndex, 'value_2', e.target.value)}
        style={{
          border: 'none',
          borderBottom: '1px dashed gray'
        }}
      />
    </Col>
    <Col span={4} style={{...getTotalStyle(sub.value_1, sub.value_2), textAlign: 'center', marginTop: '10px'}} >
      {formatCurrency(sub.value_1 - sub.value_2)}
    </Col>
    <Col span={2} style={{ textAlign: 'center' }}>
      <MinusCircleOutlined onClick={(e) => handleDeleteSubcategory(catIndex, subIndex, e)} />
    </Col>
  </Row>
));

const SortableList = SortableContainer(({ items, catIndex, handleSubcategoryChange, handleDeleteSubcategory }) => {
  return (
    <Card style={{marginTop: '10px'}}>
      {items.map((sub, subIndex) => (
        <SortableItem
          key={`item-${subIndex}`}
          index={subIndex}
          sub={sub}
          catIndex={catIndex}
          subIndex={subIndex}
          handleSubcategoryChange={handleSubcategoryChange}
          handleDeleteSubcategory={handleDeleteSubcategory}
        />
      ))}
    </Card>
  );
});

const AddNewPolicyPage = () => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [policyData, setPolicyData] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { policyId, selectedTemplate } = useParams();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        let data;
        if (policyId) {
          const response = await policy.getById(policyId);
          if (response === null) {
            navigate('/dashboard');
            return; 
          }
          console.log(response)
          data = response;
        } else {
          const response = await template.getTemplateById(selectedTemplate);
          //console.log(response);
          if (response.data === null) {
            navigate('/dashboard');
            return; 
          }
          data = response;
        }
        console.log(data.note_with_pricing);
        

        setPolicyData({
          policy_name: data.policy_name,
          company_name: data.company_name,
          compare_with: data.compare_with,
          note_with_pricing: data.note_with_pricing,
          categories: data.categories.map(category => ({
            name: category.name,
            value_1: category.value_1,
            value_2: category.value_2,
            subcategories: category.subcategories.map(sub => ({
              name: sub.name,
              value_1: sub.values[0].value_1,
              value_2: sub.values[0].value_2
            }))
          }))
        });
        setCategories(data.categories.map(category => ({
          name: category.name,
          value_1: category.value_1,
          value_2: category.value_2,
          subcategories: category.subcategories.map(sub => ({
            name: sub.name,
            value_1: sub.values[0].value_1,
            value_2: sub.values[0].value_2
          }))
          
        })));
        setLoading(false);
      } catch (error) {
        message.error('Failed to fetch policy data');
        setLoading(false);
      }
    };
    fetchData();
  }, [policyId, selectedTemplate]);

  const handleSave = async () => {

    const updatedPolicyData = {
      ...policyData,
      categories: categories.map(category => ({
        ...category,
        subcategories: category.subcategories.map((sub, index) => ({
          name: sub.name,
          position: index, // Add position
          values: [
            {
              value_1: sub.value_1,
              value_2: sub.value_2
            }
          ]
        }))
      }))
    };
    //console.log('Sending data to server:', JSON.stringify(updatedPolicyData, null, 2));
    try {
      setBtnLoading(true);
      if (policyId) {
        console.log(updatedPolicyData);
        await policy.update(policyId, updatedPolicyData);
        message.success('Policy updated successfully');
      } else {
        await policy.post(updatedPolicyData);
        message.success('Policy saved successfully');
        navigate('/dashboard');
        
      }
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);  
      console.log(error);
    
      if (error.response?.data?.errors) {
        const errorMessages = error.response.data.errors;
        
        // Show multiple errors in Ant Design message component
        message.error({
          content: (
            <div style={{ margin: 0 }}>
              {errorMessages.map((err, index) => (
                <p key={index} style={{margin:0, padding: 0}}>{err}</p>
              ))}
            </div>
          ),
          duration: 5, // Keeps the message visible longer
        });
      } else {
        message.error('Something went wrong. Please try again.');
      }
    }
  }

  const handleAddCategory = () => {
    setIsModalVisible(true);
    form.resetFields();
  };

  const handleModalOk = () => {
    form.validateFields().then(values => {
      if (!values.categoryName.trim()) {
        message.error('Category name cannot be empty');
        return;
      }
      setCategories([...categories, { name: values.categoryName, subcategories: [] }]);
      setIsModalVisible(false);
      form.resetFields();
    }).catch(info => {
      console.log('Validate Failed:', info);
    });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubcategoryChange = (catIndex, subIndex, field, value) => {
    const newCategories = [...categories];
    if (field === 'name') {
      newCategories[catIndex].subcategories[subIndex][field] = value;
    } else {
      newCategories[catIndex].subcategories[subIndex][field] = parseInt(value, 10) || 0;
    }
    setCategories(newCategories);
  };

  const handleAddSubcategory = (catIndex) => {
    const newCategories = [...categories];
    newCategories[catIndex].subcategories.push({ name: 'Add Data', value_1: 0, value_2: 0 });
    setCategories(newCategories);
  };

  const handleDeleteCategory = (catIndex, e) => {
    e.stopPropagation();
    const newCategories = categories.filter((_, index) => index !== catIndex);
    setCategories(newCategories);
  };

  const handleDeleteSubcategory = (catIndex, subIndex, e) => {
    e.stopPropagation();
    const newCategories = [...categories];
    newCategories[catIndex].subcategories = newCategories[catIndex].subcategories.filter((_, index) => index !== subIndex);
    setCategories(newCategories);
  };
 
  

  const calculateTotal = (field) => {
    return categories.reduce((total, category) => {
      return total + category.subcategories.reduce((subTotal, sub) => subTotal + (parseInt(sub[field], 10) || 0), 0);
    }, 0);
  };

  const handleMenuClick = (index) => {
    setActiveKey([index.toString()]);
  };

  const handleSortEnd = ({ oldIndex, newIndex, catIndex }) => {
    const newCategories = [...categories];
    newCategories[catIndex].subcategories = arrayMove(newCategories[catIndex].subcategories, oldIndex, newIndex);
    setCategories(newCategories);
  };

  return (
    <PageLayout style={{ minHeight: '100vh' }}>
      <Layout>
        {loading ?
        <Sider width={200} theme="light">
        <Skeleton size="large" block={true} active paragraph={{ rows: 21 }} style={{ width: '90%', margin: '0 auto' }} />
        </Sider>
 : <Sider width={200} theme="light">
 <Menu mode="inline" style={{ height: '100%', borderRight: 0 }}>
   {categories.map((category, index) => (
     <Menu.Item key={index} onClick={() => handleMenuClick(index)}>
       {category.name}
     </Menu.Item>
   ))}
 </Menu>
</Sider>}
        
        <Layout style={{ padding: '0 24px 24px' }} className='policy'>
          {loading ? (
          
          <>
          <Skeleton size="large" active paragraph={{ rows: 5 }} style={{ width: '99%', margin: '0 auto' }} />
          <Skeleton size="large" active paragraph={{ rows: 8 }} style={{ width: '99%', margin: '0 auto' }} />
          <Skeleton size="large" active paragraph={{ rows: 7 }} style={{ width: '99%', margin: '0 auto' }} />
          </>
          ) : (
          <>
          <Content style={{ padding: 24, margin: 0, minHeight: 280 }}>
            
          <Card title={policyId ? 'Policy Details' : 'Edit Policy Details'} style={{ marginBottom: '20px' }}>

            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={8}>
                <Form.Item label="Policy Name" required>
                <Input
                  value={policyData?.policy_name}
                  onChange={e => setPolicyData({ ...policyData, policy_name: e.target.value })}
                />
              </Form.Item>
                </Col>

                <Col span={8}>
                <Form.Item label="Company Name" required>
                <Input
                  value={policyData?.company_name}
                  onChange={e => setPolicyData({ ...policyData, company_name: e.target.value })}
                />
              </Form.Item>
                </Col>

                <Col span={8}>
                <Form.Item label="Compare With" required>
                <Input
                  value={policyData?.compare_with}
                  onChange={e => setPolicyData({ ...policyData, compare_with: e.target.value })}
                />
              </Form.Item>
                </Col>
                </Row>
            </Form>
            </Card>
            <Collapse activeKey={activeKey} onChange={setActiveKey}>
              {categories.map((category, catIndex) => (
                <Panel
                  header={category.name}
                  key={catIndex.toString()}
                  extra={<DeleteOutlined onClick={(e) => handleDeleteCategory(catIndex, e)} />}
                >
                  <Row gutter={16} style={{ marginBottom: '10px' }}>
                   
                    <Col span={6}>
                      <b>Coverages</b>
                    </Col>

                    <Col span={6}>
                    {policyData?.company_name}
                    </Col>

                    <Col span={6}>
                    <div>{policyData?.compare_with}</div>
                    </Col>

                    <Col style={{ textAlign: 'center'}} span={4}>
                    <div>+ / -</div>
                    </Col>
                  </Row>

                  <Card>
                  <Row gutter={16} style={{ marginBottom: '12px' }}>
                    <Col span={6}>
                    Premiums
                    </Col>
                    <Col span={6}>
                      <Input
                        prefix="$"
                        placeholder='Enter Premium Value'
                        value={category.value_1}
                        onChange={e => {
                          const newCategories = [...categories];
                          newCategories[catIndex].value_1 = parseInt(e.target.value, 10) || 0;
                          setCategories(newCategories);
                        }}
                        style={{
                          border: 'none',
                          borderBottom: '1px dashed gray'
                        }}
                      />
                    </Col>
                    <Col span={6}>
                      <Input
                        prefix="$"
                        placeholder='Enter Premium Value'
                        value={category.value_2}
                        onChange={e => {
                          const newCategories = [...categories];
                          newCategories[catIndex].value_2 = parseInt(e.target.value, 10) || 0;
                          setCategories(newCategories);
                        }}
                        style={{
                          border: 'none',
                          borderBottom: '1px dashed gray'
                        }}
                      />
                    </Col>
                    <Col span={4} style={{ ...getTotalStyle(category.value_1, category.value_2), textAlign: 'center', marginTop: '10px' }}>
                      {formatCurrency(category.value_1 - category.value_2)}
                    </Col>
                  </Row>
                  </Card>

                  

                  <SortableList
                    items={category.subcategories}
                    catIndex={catIndex}
                    onSortEnd={({ oldIndex, newIndex }) => handleSortEnd({ oldIndex, newIndex, catIndex })}
                    handleSubcategoryChange={handleSubcategoryChange}
                    handleDeleteSubcategory={handleDeleteSubcategory}
                    useDragHandle
                  />

                  <Button style={{ marginBottom: '10px' }} type="dashed" onClick={() => handleAddSubcategory(catIndex)} icon={<PlusOutlined />}>
                    Add Subcategory
                  </Button>
                </Panel>
              ))}
            </Collapse>

            

            <div style={{ marginBottom: '20px', marginTop: '20px' }}>
            <Row gutter={16}>
              <Col lg={12} md={8} sm={24} xs={24}>
              <Button  type="primary" onClick={handleAddCategory} icon={<PlusOutlined />}>
              Add Category
            </Button>
              </Col>


              <Col style={{ textAlign: 'right' }} lg={12} md={8} sm={24} xs={24}>

              <Space>
              <Button block loading={btnLoading} type="primary" onClick={handleSave} icon={<EditOutlined />}>
              {policyId ? 'Update Policy' : 'Save Policy'}
            </Button>
            
              {/* {policyId && (
            <Link to={`/policy/${policyId}/preview`}>
              <Button block type="default" icon={<EditOutlined />}>
                Preview Policy
              </Button>
            </Link>
            )} */}
              </Space>

              </Col>
              
            </Row>
            
            
            </div>

            <Card style={{marginTop: '10px'}}>
                  <Row gutter={16}>
          <Col span={24}>
          <Form.Item label="Note with pricing">
                <Input.TextArea
                  value={policyData?.note_with_pricing}
                  onChange={e => setPolicyData({ ...policyData, note_with_pricing: e.target.value })}
                  rows={6}
                  placeholder="Enter a note here if you want it to appear with pricing for printing"
                  prefix={<FileTextOutlined />}
                />
              </Form.Item>
          </Col>
        </Row>
                  </Card>

        <div style={{marginTop: '10px', marginBottom: '20px'}}>
          
          <div style={{ color: calculatePercentageDifference(
                                  policyData,
                                  calculateTotal('value_1'),
                                  calculateTotal('value_2')
                                ).percentage >= 100 ? "green" : "red" }}> 
            {calculatePercentageDifference(
              policyData,
              calculateTotal('value_1'),
              calculateTotal('value_2')
            ).percentage.toFixed(2)}%
          </div>
          
          </div>

            <Affix offsetBottom={-4}>
  <Row gutter={16} style={{ height: '60px', background: '#f3f6fe', lineHeight: '30px' }}>
    {loading ? (
      <>
        <Col xs={24} sm={24} md={10} lg={8}>
          <Skeleton size="large" active paragraph={{ rows: 0 }} />
        </Col>
        <Col xs={10} sm={10} md={6} lg={4}>
          <Skeleton size="large" active paragraph={{ rows: 0 }} />
        </Col>
        <Col xs={10} sm={10} md={6} lg={4}>
          <Skeleton size="large" active paragraph={{ rows: 0 }} />
        </Col>
        <Col xs={10} sm={10} md={6} lg={4}>
          <Skeleton size="large" active paragraph={{ rows: 0 }} />
        </Col>
      </>
    ) : (
      <>
        <Col xs={24} sm={24} md={10} lg={6}>
          <p style={{ fontWeight: 'bold' }}>Summary</p>
        </Col>
        <Col xs={10} sm={10} md={6} lg={6}>
          <div style={{...getTotalStyle(calculateTotal('value_1'), calculateTotal('value_2')), textAlign: 'center', marginTop: '10px'}}>
            <p style={{ textAlign: 'center' }}>
            {policyData?.company_name}   {formatCurrency(calculateTotal('value_1'))}
            </p>
          </div>
        </Col>
        <Col xs={10} sm={10} md={6} lg={6}>
        <div style={{...getTotalStyle(calculateTotal('value_2'), calculateTotal('value_1')), textAlign: 'center', marginTop: '10px'}}>
            <p style={{ textAlign: 'center' }}>
            {policyData?.compare_with}  {formatCurrency(calculateTotal('value_2'))}
            </p>
          </div>
        </Col>
        <Col xs={10} sm={10} md={6} lg={4} style={{ textAlign: 'center' }}>
        <p 
        // style={{...getTotalDifferenceStyle(calculateTotal('value_1') - calculateTotal('value_2'))}}
        >
            {formatCurrency(calculateTotal('value_1') - calculateTotal('value_2'))}
          </p>
        </Col>
        
      </>
    )}
  </Row>
</Affix>

          </Content>
          </>
          )}
        </Layout>
      </Layout>
      <Modal
        // title="Add New Category"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="categoryName"
            label="Category Name"
            rules={[{ required: true, message: 'Please input the category name!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </PageLayout>
  );
};

export default AddNewPolicyPage;
