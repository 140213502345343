import React from 'react';
import { Button, Layout as AntLayout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from './../../redux/userSlice';
import './layout.css';
import { DashboardOutlined, UserOutlined, ProfileOutlined, LogoutOutlined, LoginOutlined, UserAddOutlined } from '@ant-design/icons';

const { Header, Content, Footer } = AntLayout;

const PageLayout = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentUser } = useSelector((state) => state.user);

  const handleLogout = () => {
    dispatch(logout())
    navigate('/login');
  };

  const handleButtonClick = (key) => {
    if (key === 'logout') {
      handleLogout();
    } else {
      navigate(key);
    }
  };

  return (
    <AntLayout className="layout">
      <Header className="layout-header">
        <div className="logo">Value Sales Presentation</div>
        <div className="menu-buttons">
          {currentUser ? (
            <>
              <Button color="primary" variant="outlined" icon={<DashboardOutlined />} onClick={() => handleButtonClick('/dashboard')}>Dashboard</Button>
              {currentUser?.role === 'admin' && (
                <Button color="primary" variant="outlined" icon={<UserOutlined />} onClick={() => handleButtonClick('/admin/users')}>Users</Button>
              )}
              <Button color="primary" variant="outlined" icon={<ProfileOutlined />} onClick={() => handleButtonClick('/profile')}>Profile</Button>
              <Button color="primary" variant="outlined" icon={<LogoutOutlined />} onClick={() => handleButtonClick('logout')}>Logout</Button>
            </>
          ) : (
            <>
              {/* <Button icon={<LoginOutlined />} onClick={() => handleButtonClick('/login')}>Login</Button> */}
              {/* <Button icon={<UserAddOutlined />} onClick={() => handleButtonClick('/register')}>Register</Button> */}
            </>
          )}
        </div>
      </Header>
      <Content className="layout-content">
        {children}
      </Content>
      <Footer className="layout-footer">
        ©2025 Value Sales Presentation. All rights reserved.
      </Footer>
    </AntLayout>
  );
};

export default PageLayout;
