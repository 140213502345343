import React, { useState, useEffect } from "react";
import { Table, Input, Space, Button, message, Popconfirm, Badge, Dropdown, Menu, Modal, Select, Skeleton, Row, Col } from "antd";
import { SearchOutlined, EditOutlined, DeleteOutlined, SettingOutlined, EyeOutlined, PrinterOutlined, FilePdfOutlined, StarOutlined, StarFilled, BarChartOutlined } from "@ant-design/icons";
import moment from "moment"; 
import { calculateCategoryTotals, formatCurrency } from "../../utils/helpers";
import { Link } from 'react-router-dom';
import policy from "../../services/policy";
import template from "../../services/template";
import { useNavigate } from 'react-router-dom';
import PageLayout from "../../components/layout/Layout";
import { generatePDF } from "../../utils/pdfUtils";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';

const { Option } = Select;

const PoliciesPage = () => {
  const [allPolicies, setAllPolicies] = useState([]); // Store all policies
  const [policies, setPolicies] = useState([]); // Displayed policies
  const [templates, setTemplates] = useState([]); // Store all templates
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isChartModalVisible, setIsChartModalVisible] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const navigate = useNavigate();

  // Fetch Policies from API
  const fetchPolicies = async () => {
    setLoading(true);
    try {
      const response = await policy.getAll();

      if (response.data) {
        const policiesWithTotals = response.data.map(policy => {
          const categoriesData = policy.categories;
          const updatedValues = {};

          categoriesData.forEach((category) => {
            updatedValues[category.name] = category.subcategories.map((subcategory) => ({
              label: subcategory.name,
              value_1: subcategory.values.map((value) => value.value_1),
              value_2: subcategory.values.map((value) => value.value_2),
            }));
          });

          const { totalValue1, totalValue2 } = calculateCategoryTotals(updatedValues);

          return {
            ...policy,
            totalValue1,
            totalValue2,
          };
        });

        setAllPolicies(policiesWithTotals); // Store all policies
        setPolicies(policiesWithTotals); // Display all policies initially
      }
    } catch (error) {
      console.error("Error fetching policies:", error);
      message.error("Failed to load policies. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch Templates from API
  const fetchTemplates = async () => {
    try {
      const response = await template.getAllTemplates();
      if (response.data) {
        setTemplates(response.data);
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
      message.error("Failed to load templates. Please try again.");
    }
  };

  // Effect to Load Policies and Templates on Mount
  useEffect(() => {
    fetchPolicies();
    fetchTemplates();
  }, []);

  // Handle Search Input Change
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
  
    const filteredPolicies = allPolicies.filter(policy =>
      Object.keys(policy).some(key =>
        String(policy[key]).toLowerCase().includes(value)
      )
    );
  
    setPolicies(filteredPolicies); // Update displayed policies
  };

  // Handle Delete Policy
  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await policy.delete(id);
      message.success("Policy deleted successfully!");
      fetchPolicies(); // Refresh data
    } catch (error) {
      console.error("Error deleting policy:", error);
      message.error("Failed to delete policy. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const showChart = (policyData) => {
    const data = policyData.categories.map(category => ({
      name: category.name,
      company_name: category.subcategories.reduce((acc, sub) => acc + sub.values.reduce((sum, val) => sum + val.value_1, 0), 0),
      compare_with: category.subcategories.reduce((acc, sub) => acc + sub.values.reduce((sum, val) => sum + val.value_2, 0), 0),
    }));
    setChartData(data);
    setIsChartModalVisible(true);
    setSelectedPolicy(policyData);
  };

  // Handle Save PDF
  const handleSavePDF = (policyData) => {
    generatePDF(policyData);
  };

  const handleToggleMarkTemplate = async (id) => {
    setLoading(true);
    try {
      const response = await policy.toggleMarkTemplate(id);
      fetchTemplates();
      message.success(response.message);
      setAllPolicies((prevPolicies) =>
        prevPolicies.map((policy) =>
          policy.id === id ? { ...policy, is_template: !policy.is_template } : policy
        )
      );
      setPolicies((prevPolicies) =>
        prevPolicies.map((policy) =>
          policy.id === id ? { ...policy, is_template: !policy.is_template } : policy
        )
      );
    } catch (error) {
      console.error("Error toggling template saving status:", error);
      message.error("Failed to toggle template saving status. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle Add Value Sales Presentation
  const handleAddVSP = () => {
    setIsModalVisible(true);
  };

  const handleTemplateSelect = (value) => {
    setSelectedTemplate(value);
  };

  const handleOk = () => {
    if (selectedTemplate) {
      navigate(`/vsp/add/${selectedTemplate}/template`);
    } else {
      message.warning("Please select a template.");
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Table Columns
  const columns = [
    {
      title: "Policy Name",
      dataIndex: "policy_name",
      key: "policy_name",
      sorter: (a, b) => a.policy_name.localeCompare(b.policy_name),
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      render: (value, record) => {
        let color = "#ffffff";
        if (record.totalValue1 > record.totalValue2) {
          color = "#ccff99"; 
        } else if (record.totalValue1 < record.totalValue2) {
          color = "#ffff99"; 
        }

        return (
          <Space direction="vertical">
            {value}
            <Badge style={{ background: color, color: 'black' }} count={formatCurrency(record.totalValue1)} />
          </Space>
        );
      },
    },
    {
      title: "Compare With",
      dataIndex: "compare_with",
      key: "compare_with",
      render: (value, record) => {
        let color = "#ffffff";
        if (record.totalValue1 > record.totalValue2) {
          color = "#ffff99"; 
        } else if (record.totalValue1 < record.totalValue2) {
          color = "#ccff99"; 
        }

        return (
          <Space direction="vertical">
            {value}
            <Badge style={{ background: color, color: 'black' }} count={formatCurrency(record.totalValue2)} />
          </Space>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (text) => (
        <Badge
          count={moment(text).format("YYYY-MM-DD")}
          style={{
            backgroundColor: "#52c41a", // Green color badge
            color: "#fff",
            padding: "0 12px",
            borderRadius: "12px",
          }}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        const menu = (
          <Menu>
            <Menu.Item key="edit">
              <Link to={`/vsp/${record.id}/edit`}>
                <EditOutlined/> Edit
              </Link>
            </Menu.Item>
            <Menu.Item key="preview">
            <Link to={`/policy/${record.id}/preview`}>
              <EyeOutlined style={{ marginRight: '1px'}} /> Preview Policy
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="savePDF" onClick={() => handleSavePDF(record)}>
              <FilePdfOutlined className="mr-icon" /> Save PDF
            </Menu.Item> */}

            {/* <Menu.Item key="chart" onClick={() => showChart(record)}>
              <BarChartOutlined className="mr-icon" /> Chart
            </Menu.Item> */}
            <Menu.Item key="toggleMarkTemplate" onClick={() => handleToggleMarkTemplate(record.id)}>
              {record.is_template ? (
                <>
                  <StarFilled className="mr-icon" /> Remove Template
                </>
              ) : (
                <>
                  <StarOutlined className="mr-icon" /> Save as Template
                </>
              )}
            </Menu.Item>
            {/* <Menu.Item key="policyHistory">
            <Link to={`/policy-history/${record.id}`}>
                <EditOutlined/> Policy History
              </Link>
            </Menu.Item> */}
            <Menu.Item key="delete">
              <Popconfirm
                title="Are you sure you want to delete this policy?"
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined className="mr-icon" style={{ marginRight: '1px'}} /> Delete
              </Popconfirm>
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu}>
            <Button style={{ border: 0 }}>
            <SettingOutlined /> 
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const getColorForTotal = (totalValue1, totalValue2) => {
    if (totalValue1 > totalValue2) {
      return "#ccff99"; // Light green
    } else if (totalValue1 < totalValue2) {
      return "#ffff99"; // Light yellow
    }
    return "#000"; 
  };

  const getColorForBar = (value1, value2) => {
    if (value1 > value2) {
      return "#ccff99"; // Light green
    } else if (value1 < value2) {
      return "#ffff99"; // Light yellow
    }
    return "#8884d8"; // Default color
  };

  return (
    <PageLayout style={{ padding: "20px" }}>
      <div style={{ marginBottom: '10px' }}>
        <Button type="primary" onClick={handleAddVSP}>Add Value Sales Presentation</Button>
      </div>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        
      <><Input
  placeholder="Search policies"
  value={searchText}
  onChange={handleSearch}
  prefix={<SearchOutlined />}
  allowClear
/>
<Table
  size="small"
  columns={columns}
  dataSource={policies}
  rowKey="id"
  loading={loading}
  pagination={{ pageSize: 10 }}
  rowClassName={(record) => (record.is_template ? 'highlight' : '')}
/></>
        
      </Space>
      <Modal
        title="Select Template"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {loading ? (<><Skeleton.Input size="large" block={true} active paragraph={{ rows: 0 }} width={100} style={{ width: '100%', margin: '0 auto' }} /></>) : (<><Select
          style={{ width: '100%' }}
          placeholder="Select a template"
          onChange={handleTemplateSelect}
        >
          {templates.map(template => (
            <Option key={template.id} value={template.id}>
              {template.policy_name}
            </Option>
          ))}
        </Select></>)}
        
      </Modal>
      <Modal
        visible={isChartModalVisible}
        onCancel={() => setIsChartModalVisible(false)}
        footer={null}
        width={1400}
      >
        {selectedPolicy && (
          <>
            <h3 style={{textAlign: 'center'}}>{selectedPolicy.policy_name}</h3>
            <Row gutter={16}>
  <Col span={12}>
    <p
      style={{
        backgroundColor: getColorForTotal(selectedPolicy.totalValue1, selectedPolicy.totalValue2),
        padding: '10px',
        textAlign: 'center',
        borderRadius: '5px',
      }}
    >
    {selectedPolicy.company_name} {formatCurrency(selectedPolicy.totalValue1)}
    </p>
  </Col>
  <Col span={12}>
    <p
      style={{
        backgroundColor: getColorForTotal(selectedPolicy.totalValue2, selectedPolicy.totalValue1),
        padding: '10px',
        textAlign: 'center',
        borderRadius: '5px',
      }}
    >
     {selectedPolicy.compare_with} {formatCurrency(selectedPolicy.totalValue2)}
    </p>
  </Col>
</Row>
</>
        )}
        <ResponsiveContainer height={500}>
          <BarChart data={chartData} margin={{ bottom: 120 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" interval={0} angle={10} textAnchor="end" tick={{ fontSize: 12 }} dy={10} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="company_name" name={selectedPolicy?.company_name} fill={getColorForBar(selectedPolicy?.company_name, selectedPolicy?.compare_with)} barSize={40}>
              {chartData.map((entry, index) => (
                <Cell  key={`cell-${index}`} fill={getColorForBar(entry.company_name, entry.compare_with)} />
              ))}
            </Bar>
            <Bar dataKey="compare_with" name={selectedPolicy?.compare_with} fill={getColorForBar(selectedPolicy?.compare_with, selectedPolicy?.company_name)} barSize={40}>
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={getColorForBar(entry.compare_with, entry.company_name)} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Modal>
    </PageLayout>
  );
};


export default PoliciesPage;
