import api from './../libs/api';

export default {
  
  update: (data) => {
  return new Promise((resolve, reject) => {
      api()
          .put(`/profile`, data)
          .then(({ status, data }) => {
              if (status === 200) resolve(data);
              throw new Error(`Failed with status: ${status}`);
          })
          .catch(error => reject(error));
  });
},

get: () => {
  return new Promise((resolve, reject) => {
      api()
          .get(`/profile`)
          .then(({ status, data }) => {
              if (status === 200) resolve(data);
              throw new Error(`Failed with status: ${status}`);
          })
          .catch(error => reject(error));
  });
},

uploadPicture: (data) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('profile_picture', data); // Ensure 'file' is the correct key expected by the backend

    api()
      .post('/profile/picture', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ status, data }) => {
        if (status === 200) {
          resolve(data);
        } else {
          throw new Error(`Update Profile failed with status: ${status}`);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
},



}