import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AddNewPolicyPage from './pages/policy/index';
import PreviewPolicyPageWrapper from './pages/policy/PreviewPolicyPage';
import Login from './pages/login';
import ForgotPassword from './pages/forgotPassword';
import Register from './pages/register';
import ProfilePage from './pages/profile';
import PoliciesPage from './pages/policies/index';
import UsersPage from './pages/users/index';
// import PolicyHistory from './pages/policyHistory';

// PrivateRoute Component
const PrivateRoute = ({ element, isAuthenticated, requiredRole, ...rest }) => {
  const location = useLocation();
  const { currentUser } = useSelector((state) => state.user);
  const userRole = currentUser?.role;

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ prevUrl: location.pathname + location.search }} replace />;
  }

  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to="/dashboard" replace />;
  }

  return element;
};

// PublicRoute Component
const PublicRoute = ({ element, isAuthenticated, ...rest }) => {
  return isAuthenticated ? <Navigate to="/dashboard" replace /> : element;
};

function App() {
  const { currentUser } = useSelector((state) => state.user);
  const isAuthenticated = currentUser?.token;

  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route
            path="/login"
            element={
              <PublicRoute
                isAuthenticated={isAuthenticated}
                element={<Login />}
              />
            }
          />
          {/* <Route
            path="/register"
            element={
              <PublicRoute
                isAuthenticated={isAuthenticated}
                element={<Register />}
              />
            }
          /> */}
          {/* <Route
            path="/forgot-password"
            element={
              <PublicRoute
                isAuthenticated={isAuthenticated}
                element={<ForgotPassword />}
              />
            }
          /> */}

          {/* Protected Routes */}
          <Route
            path="/profile"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                element={<ProfilePage />}
              />
            }
          />
          
          <Route
            path="/vsp/add/:selectedTemplate/template"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                element={<AddNewPolicyPage />}
              />
            }
          />
          <Route
            path="/vsp/:policyId/edit"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                element={<AddNewPolicyPage />}
              />
            }
          />
          <Route
            path="/policy/:policyId/preview"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                element={<PreviewPolicyPageWrapper />}
              />
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                element={<PoliciesPage />}
              />
            }
          />
          <Route
            path="/admin/users"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                requiredRole="admin"
                element={<UsersPage />}
              />
            }
          />
          {/* <Route
            path="/policy-history/:policyId"
            element={
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                element={<PolicyHistory />}
              />
            }
          /> */}

          {/* Fallback Route */}
          <Route path="*" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
