import React, {useState} from 'react';
import { Form, Input, Button, Select, Checkbox, message, Space } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import './index.css'; // Optional: For additional custom styling
import TermsAndConditions from './../termsAndConditions/index';
import auth from '../../services/auth';
import PageLayout from '../../components/layout/Layout';

const { Option } = Select;

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await auth.register(values);
      message.success('Registration successful!');
      navigate('/login');
    } catch (error) {
      setLoading(false);
      console.error('Registration Error:', error);
      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.data.message === 'Email already exists') {
          message.error('Email already exists. Please use a different email.');
        } else {
          message.error(error.response.data.message);
        }
      } else {
        message.error('Registration failed. Please try again.');
      }
    }
  };

  return (
    <PageLayout className="container">
      <Form
        name="register_form"
        className="register-form"
        onFinish={onFinish}
      >
        <h2 className="register-title">Register Now</h2>
        
        <Form.Item
          name="name"
          rules={[
            { required: true, message: 'Please input your name!' },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder="Name"
          />
        </Form.Item>
        
        <Form.Item
          name="email"
          rules={[
            { type: 'email', message: 'The input is not a valid email address!' },
            { required: true, message: 'Please input your email!' },
          ]}
        >
          <Input
            prefix={<MailOutlined />}
            placeholder="Email"
          />
        </Form.Item>
        
        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Please input your password!' },
            { min: 6, message: 'Password must be at least 6 characters long!' },
          ]}
          hasFeedback
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Password"
          />
        </Form.Item>
        
        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          hasFeedback
          rules={[
            { required: true, message: 'Please confirm your password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords do not match!')
                );
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Confirm Password"
          />
        </Form.Item>
     
        
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className="register-form-button"
          >
            Register
          </Button>
        </Form.Item>

        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <Form.Item>
          <Link to="/login">
            <Button type="link" className="login-button">
              Have an account? Login
            </Button>
          </Link>
          </Form.Item>


          <Form.Item >
        <TermsAndConditions  />
        </Form.Item>

        </Space>

      </Form>
    </PageLayout>
  );
};

export default Register;
