import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Layout,
  Row,
  Col,
  Button,
  Spin,
  Space,
  Affix,
  Skeleton,
  Checkbox,
  Popover,
} from "antd";
import { useReactToPrint } from "react-to-print";
import {
  formatCurrency,
  getTotalStyle,
  calculateTotalAnnual,
  calculateTotalMonthly,
  reverseGetTotalStyle,
} from "../../utils/helpers";
import { calculatePercentageDifference, calculateTotal } from "../../utils/pdfUtils"; 
import { FilterOutlined, PrinterOutlined } from "@ant-design/icons";
import env from "../../env";
import PageLayout from "../../components/layout/Layout";
import policy from "../../services/policy";
import "./index.css";

const { Content } = Layout;

const PreviewPolicyPage = () => {
  const { policyId } = useParams();
  const [loading, setLoading] = useState(true);
  const [policyData, setPolicyData] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);

  const filteredPolicyData = policyData
    ? {
        ...policyData,
        categories: policyData.categories.filter((category) =>
          filteredCategories.includes(category.name),
        ),
      }
    : null;

  const handlePrint = () => {
    setIsPrinting(true);
    setTimeout(() => {
      reactToPrintFn();
      setIsPrinting(false);
    }, 1000);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data;
        if (policyId) {
          const response = await policy.getById(policyId);
          data = response;
        }
        setPolicyData({
          policy_name: data.policy_name,
          company_name: data.company_name,
          compare_with: data.compare_with,
          note_with_pricing: data.note_with_pricing,
          user: data.user,
          categories: data.categories.map((category) => ({
            name: category.name,
            value_1: category.value_1,
            value_2: category.value_2,
            subcategories: category.subcategories.map((sub) => ({
              name: sub.name,
              values: [
                {
                  value_1: sub.values[0].value_1,
                  value_2: sub.values[0].value_2,
                },
              ],
            })),
          })),
        });
        setFilteredCategories(data.categories.map((category) => category.name));
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch policy data", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [policyId]);

  const handleCategoryFilterChange = (categoryName) => {
    setFilteredCategories((prev) =>
      prev.includes(categoryName)
        ? prev.filter((name) => name !== categoryName)
        : [...prev, categoryName],
    );
  };

  const contentRef = useRef(null);
  const title = policyData ? policyData.policy_name : "Policy";

  const reactToPrintFn = useReactToPrint({ 
    contentRef, 
    documentTitle: title,
    
  });

  const content = (
    <Space direction="vertical" style={{ padding: 20 }}>
      {policyData &&
        policyData.categories.map((category, index) => (
          <Checkbox
            key={index}
            checked={filteredCategories.includes(category.name)}
            onChange={() => handleCategoryFilterChange(category.name)}
          >
            {category.name}
          </Checkbox>
        ))}
    </Space>
  );

  return (
    <PageLayout>
      <Layout>
        {loading ? (
          <>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Skeleton size="large" active paragraph={{ rows: 18 }} />
              </Col>
              <Col span={5}>
                <Skeleton size="large" active paragraph={{ rows: 18 }} />
              </Col>
              <Col span={5}>
                <Skeleton size="large" active paragraph={{ rows: 18 }} />
              </Col>
              <Col span={5}>
                <Skeleton size="large" active paragraph={{ rows: 18 }} />
              </Col>
            </Row>
          </>
        ) : (
          <div ref={contentRef}>
            <div className="cover-page cover-page-setup">
              {policyData && (
                <div className="text-center">
                  <img
                    style={{ marginTop: '140px' }}
                    width={260}
                    src={`${env.BACKEND_STORAGE_URL}/${policyData?.user?.profile_picture}`}
                    alt="Profile"
                  />
                  <h3>{policyData.user.coversheet_tagline}</h3>
                  <h1>{policyData.policy_name}</h1>
                  <p>by</p>
                  <h3>
                    {policyData.user.name} {policyData.user.last_name}
                  </h3>
                  <p style={{ marginTop: "-10px" }}>
                    License: {policyData.user.license_number}
                  </p>
                  <h2 style={{ marginBottom: "40px", marginTop: "40px" }}>
                    {policyData.user.agency_name}
                  </h2>
                  <p style={{ marginBottom: "40px" }}>
                    {policyData.user.address}
                  </p>
                  <p>
                    <b>
                      {policyData.user.phone} - {policyData.user.email}
                    </b>
                  </p>
                  <p style={{ fontSize: "12px", lineHeight: "22px", marginTop: "60px" }}>
                    {policyData.user.coversheet_products}
                  </p>
                </div>
              )}
            </div>
            <div className="page-break" />
            <div className="page-1 page-1-setup">
              <div style={{ padding: "20px", paddingTop: '0', backgroundColor: "white" }}>
                <div className="text-center">
                  {policyData && (
                    <div>
                      <h2>{policyData?.user?.agency_name}</h2>
                      <h3>{policyData?.policy_name}</h3>
                    </div>
                  )}
                  <p style={{ marginTop: "-10px" }}>
                    Comprehensive Coverage Analysis
                  </p>
                </div>

                <Row gutter={[16, 16]}>
                  <Col span={8} className="text-center"></Col>
                  <Col span={5}>
                    <b>{policyData?.company_name}</b>
                  </Col>
                  <Col span={5}>
                    <b>{policyData?.compare_with}</b>
                  </Col>
                  <Col span={5} className="text-center">
                    <b>+ / -</b>
                  </Col>
                </Row>

                {policyData && filteredPolicyData && (
                  <>
                    {filteredPolicyData.categories.map((category, catIndex) => (
                      <React.Fragment key={catIndex}>
                        <Row gutter={[16, 16]} style={{ fontWeight: "bold" }}>
                          <Col span={24}>{category.name}</Col>
                        </Row>
                        {category.subcategories.map((sub, subIndex) => (
                          <Row
                            key={subIndex}
                            gutter={[16, 16]}
                            style={{ padding: "3px" }}
                          >
                            <Col span={8} style={{ paddingLeft: "14px" }}>
                              {sub.name}
                            </Col>
                            <Col span={5}>
                              {formatCurrency(sub.values[0].value_1)}
                            </Col>
                            <Col span={5}>
                              {formatCurrency(sub.values[0].value_2)}
                            </Col>
                            <Col
                              span={5}
                              style={{
                                textAlign: "center",
                                ...getTotalStyle(
                                  sub.values[0].value_1,
                                  sub.values[0].value_2,
                                ),
                              }}
                            >
                              {formatCurrency(
                                sub.values[0].value_1 - sub.values[0].value_2,
                              )}
                            </Col>
                          </Row>
                        ))}
                      </React.Fragment>
                    ))}
                    <Row
                      gutter={[16, 16]}
                      style={{
                        fontWeight: "bold",
                        padding: "4px",
                        marginTop: "16px",
                      }}
                    >
                      <Col span={8}>Summary</Col>
                      <Col span={5}>
                        {formatCurrency(
                          calculateTotal(filteredPolicyData, "value_1"),
                        )}
                      </Col>
                      <Col span={5}>
                        {formatCurrency(
                          calculateTotal(filteredPolicyData, "value_2"),
                        )}
                      </Col>
                      <Col
                        span={5}
                        className="text-center"
                        style={{
                          ...getTotalStyle(
                            calculateTotal(filteredPolicyData, "value_1"),
                            calculateTotal(filteredPolicyData, "value_2"),
                          ),
                        }}
                      >
                        {formatCurrency(
                          calculateTotal(filteredPolicyData, "value_1") -
                            calculateTotal(filteredPolicyData, "value_2"),
                        )}
<br/>
<div style={{ color: calculatePercentageDifference(
                        filteredPolicyData,
                        calculateTotal(filteredPolicyData, 'value_1'),
                        calculateTotal(filteredPolicyData, 'value_2')
                      ).percentage >= 100 ? "green" : "red" }}> 
  {calculatePercentageDifference(
    filteredPolicyData,
    calculateTotal(filteredPolicyData, 'value_1'),
    calculateTotal(filteredPolicyData, 'value_2')
  ).percentage.toFixed(2)}%
</div>
     
                      </Col>
                    </Row>

                   

                    <Row gutter={[16, 16]} className="bold">
                      <Col span={8}>Current Annual Premium</Col>
                      <Col span={5} className="bold">
                        <div className="border-bottom" style={{width: '80px', borderBottom: '1px solid'}}>$</div>
                      </Col>
                      <Col span={5} className="bold">
                        <div className="border-bottom">
                          {formatCurrency(
                            calculateTotalAnnual(
                              filteredPolicyData.categories,
                              "value_2",
                            ),
                          )}
                        </div>
                      </Col>
                      <Col span={5}></Col>
                    </Row>
                    
                  </>
                )}
              </div>

              {policyData?.user?.coversheet_disclosure &&
              <div className='bottom-absolute-page-1'>
                <div style={{ textAlign: 'center' }}>
                  <small>{policyData?.user?.coversheet_disclosure}</small>
                </div>
              </div>
            }
            </div>
            <div className="page-break" />
            <div className="page-2 page-2-setup">
              <div style={{ padding: "20px", paddingTop: '0', backgroundColor: "white" }}>
                <div  style={{ marginBottom: "40px" }}>
                  {policyData && (
                    <div className="text-center">
                      <h2>{policyData?.user?.agency_name}</h2>
                      <h3>{policyData?.policy_name}</h3>
                    </div>
                  )}
                  <p style={{ marginTop: "-10px" }} className="text-center">
                    Comprehensive Protection Package - Premium Analysis
                  </p>
                </div>

                <div style={{ marginBottom: "20px" }}>
                  {filteredPolicyData && (
                    <>
                      <Row gutter={[16, 16]} style={{ marginBottom: "10px" }}>
                        <Col span={8}>
                          <b>Premiums</b>
                        </Col>
                        <Col span={5} >
                          <b>{policyData?.company_name}</b>
                        </Col>
                        <Col span={5} >
                          <b>{policyData?.compare_with}</b>
                        </Col>
                        <Col span={5} className="text-center">
                          <b>+ / -</b>
                        </Col>
                      </Row>

                      <Row
                        gutter={[16, 16]}
                        style={{
                          fontWeight: "bold",
                          padding: "4px",
                          marginTop: "16px",
                        }}
                      >
                        <Col span={8}>Total Annual</Col>
                        <Col span={5}>
                          {formatCurrency(
                            calculateTotalAnnual(
                              filteredPolicyData.categories,
                              "value_1",
                            ),
                          )}
                        </Col>
                        <Col span={5}>
                          {formatCurrency(
                            calculateTotalAnnual(
                              filteredPolicyData.categories,
                              "value_2",
                            ),
                          )}
                        </Col>
                        <Col
                          span={5}
                          className="text-center"
                          style={{
                            ...reverseGetTotalStyle(
                              calculateTotalAnnual(
                                filteredPolicyData.categories,
                                "value_1",
                              ),
                              calculateTotalAnnual(
                                filteredPolicyData.categories,
                                "value_2",
                              ),
                            ),
                          }}
                        >
                          {formatCurrency(
                            calculateTotalAnnual(
                              filteredPolicyData.categories,
                              "value_1",
                            ) -
                              calculateTotalAnnual(
                                filteredPolicyData.categories,
                                "value_2",
                              ),
                          )}
                        </Col>
                      </Row>

                      <Row
                        gutter={[16, 16]}
                        style={{ fontWeight: "bold", padding: "4px" }}
                      >
                        <Col span={8}>Total Monthly</Col>
                        <Col span={5} >
                          {formatCurrency(
                            calculateTotalMonthly(
                              filteredPolicyData.categories,
                              "value_1",
                            ),
                          )}
                        </Col>
                        <Col span={5} >
                          {formatCurrency(
                            calculateTotalMonthly(
                              filteredPolicyData.categories,
                              "value_2",
                            ),
                          )}
                        </Col>
                        <Col
                          span={5}
                          className="text-center"
                          style={{
                            ...reverseGetTotalStyle(
                              calculateTotalMonthly(
                                filteredPolicyData.categories,
                                "value_1",
                              ),
                              calculateTotalMonthly(
                                filteredPolicyData.categories,
                                "value_2",
                              ),
                            ),
                          }}
                        >
                          {formatCurrency(
                            calculateTotalMonthly(
                              filteredPolicyData.categories,
                              "value_1",
                            ) -
                              calculateTotalMonthly(
                                filteredPolicyData.categories,
                                "value_2",
                              ),
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                </div>

                <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
                  <Col span={24}>
                    <b>SUMMARY (Annual Premium)</b>
                  </Col>
                </Row>

                {policyData && filteredPolicyData && (
                  <>
                    {filteredPolicyData.categories.map((category, index) => (
                      <Row
                        key={index}
                        gutter={[16, 16]}
                        style={{ marginBottom: "10px" }}
                      >
                        <Col span={8}>{category.name}</Col>
                        <Col span={5}>
                          {formatCurrency(category.value_1)}
                        </Col>
                        <Col span={5}>
                          {formatCurrency(category.value_2)}
                        </Col>
                      </Row>
                    ))}
                  </>
                )}

                {policyData?.note_with_pricing &&
                <Row
                  style={{
                    marginTop: "40px",
                    padding: "0",
                    background: "#f9f9f9",
                    border: "1px solid #f0f0f0",
                  }}
                >
                  <Col span={2} style={{ background: "#fff", padding: "10px" }}>
                    <p>
                      <small>Notes</small>
                    </p>
                  </Col>
                  <Col
                    span={22}
                    style={{ background: "#f4fcff", padding: "10px" }}
                  >
                    <p style={{ fontSize: "9px" }}>
                      <small>{policyData?.note_with_pricing}</small>
                    </p>
                  </Col>
                </Row>
                }
              </div>

              {/* <div className='bottom-absolute-page-2'>
                <div style={{ textAlign: 'center' }}>
                  <small>{policyData?.user?.coversheet_disclosure}</small>
                </div>
              </div> */}
            </div>
          </div>
        )}
        <Affix offsetBottom={24} style={{ width: "100%", textAlign: "right" }}>
          <Space style={{ padding: 24 }}>
            <Popover
              content={content}
              title="Filter Categories"
              trigger="click"
            >
              <Button type="primary" icon={<FilterOutlined />} />
            </Popover>
            <Button
              type="primary"
              icon={<PrinterOutlined />}
              onClick={handlePrint}
            />
          </Space>
        </Affix>
      </Layout>
    </PageLayout>
  );
};

export default PreviewPolicyPage;
