import api from '../libs/api';

export default {

  getAllTemplates: () => {
    return new Promise((resolve, reject) => {
        api()
            .get(`/templates`)
            .then(({ status, data }) => {
                if (status === 200) resolve(data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

  getTemplateById: (id) => {
    return new Promise((resolve, reject) => {
        api()
        .get(`/template/${id}`)
        .then(({ status, data }) => {
                if (status === 200) resolve(data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

  toggleMarkTemplate: (id) => {
    return new Promise((resolve, reject) => {
        api()
        .post(`/template/${id}/toggle-mark`)
        .then(({ status, data }) => {
                if (status === 200) resolve(data);
                throw new Error(`Failed with status: ${status}`);
            })
            .catch(error => reject(error));
    });
  },

}