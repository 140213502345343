import jsPDF from 'jspdf';

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

export const getPDFDifferenceStyle = (doc, difference) => {
  return {
    textColor: difference > 0 ? [0, 128, 0] : [255, 255, 0], // Green for positive, yellow for negative
    fontStyle: 'bold'
  };
};

export const calculateTotal = (policyData, field) => {
  return policyData.categories.reduce((total, category) => {
    return total + category.subcategories.reduce((subTotal, sub) => subTotal + (parseInt(sub.values[0][field], 10) || 0), 0);
  }, 0);
};

export const calculatePercentageDifference = (policyData, value1, value2) => {
  if (!value1 || !value2) return { percentage: 0 }; // Prevent division by zero
  
  const percentage = (value1 / value2) * 100;
  return { percentage: Math.abs(percentage) }; // Returning as a number
};


export const getTotalDifferenceStyle = (difference) => {
  if (difference > 0) {
    return { color: 'green', fontWeight: 'bold' };
  } else if (difference < 0) {
    return { color: 'yellow', fontWeight: 'bold' };
  }
  return {};
};

export const generatePDF = (policyData) => {
  const doc = new jsPDF();

  doc.setFontSize(12);
  doc.text(policyData.policy_name, 14, 22);
  doc.setFontSize(10);
  doc.text(`Company: ${policyData.company_name}`, 14, 30);
  doc.text(`Compared with: ${policyData.compare_with}`, 14, 36);

  let yOffset = 50;

  policyData.categories.forEach(category => {
    doc.setFontSize(14);
    doc.text(category.name, 14, yOffset);
    yOffset += 8;

    category.subcategories.forEach(sub => {
      doc.setFontSize(12);
      doc.text(sub.name, 20, yOffset);
      doc.text(formatCurrency(sub.values[0].value_1), 80, yOffset);
      doc.text(formatCurrency(sub.values[0].value_2), 120, yOffset);
      doc.text(formatCurrency(sub.values[0].value_1 - sub.values[0].value_2), 160, yOffset);
      yOffset += 8;
    });

    yOffset += 4;
  });

  doc.setFontSize(14);
  doc.text('Total', 14, yOffset);
  doc.text(formatCurrency(calculateTotal(policyData, 'value_1')), 80, yOffset);
  doc.text(formatCurrency(calculateTotal(policyData, 'value_2')), 120, yOffset);
  doc.text(formatCurrency(calculateTotal(policyData, 'value_1') - calculateTotal(policyData, 'value_2')), 160, yOffset);

  yOffset += 10;
  doc.setFontSize(12);
  doc.text(calculatePercentageDifference(policyData, calculateTotal(policyData, 'value_1'), calculateTotal(policyData, 'value_2')), 14, yOffset);

  doc.save('policy.pdf');
};

