import React, { useState, useEffect } from 'react';
import { Form, Upload, message, Avatar, Row, Col, Spin, Card, Typography } from 'antd';
import {  UserOutlined, CameraOutlined } from '@ant-design/icons';
import PageLayout from '../../components/layout/Layout';
import ProfileForm from '../../components/profile/ProfileForm';
import profile from '../../services/profile';
import env from '../../env';
import './index.css'; 

const { Title, Text } = Typography;

const ProfilePage = () => {
  const [form] = Form.useForm();
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [profileImageName, setProfileImageName] = useState(null);
  const [changePassword, setChangePassword] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageloading, setImageLoading] = useState(false);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setLoading(true);
        const data = await profile.get();
        setLoading(false);
        form.setFieldsValue(data.data);
        console.log(`${env.BACKEND_STORAGE_URL}/${data.data.profile_picture}`)
        if (data.data.profile_picture) {
          console.log("data.data.profile_picture: ", data.data.profile_picture);
          setProfileImageUrl(`${env.BACKEND_STORAGE_URL}/${data.data.profile_picture}`);
        }
      } catch (error) {
        setLoading(false);
        message.error('Failed to fetch profile data!');
      }
    };

    fetchProfileData();
  }, [form]);

  const handleFinish = async (values) => {
    setBtnLoading(true);
    console.log("values: ", values);
    if (profileImage) {
      values.profile_picture = profileImage.name; // Save the file name
    }
    try {
      console.log("values: ", values);
      await profile.update(values);
      message.success('Profile updated successfully!');
    } catch (error) {
      console.log(error);
      message.error('Failed to update profile!');
    } finally {
      setBtnLoading(false);
    }
  };

  const handleUploadChange = async (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    try {
      console.log("info: ", info);
      const response = await profile.uploadPicture(info.file.originFileObj);
      setProfileImage(info.file.originFileObj);
      setProfileImageUrl(URL.createObjectURL(info.file.originFileObj));
      setProfileImageName(info.file.name);
      message.success(`${info.file.name} file uploaded successfully`);
    } catch (error) {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const uploadProps = {
    name: 'profile_picture',
    accept: 'image/*',
    showUploadList: false,
    customRequest: async ({ file, onSuccess, onError }) => {
      const formData = new FormData();
      formData.append('profile_picture', file);
      try {
        setImageLoading(true);
        const response = await profile.uploadPicture(formData);
        setImageLoading(false);
        console.log("success: ", response);
        onSuccess(null, file);
      } catch (error) {
        console.log("error: ", error);
        setImageLoading(false);
        onError(error);
      }
    },
    onChange: handleUploadChange,
  };


  const toggleChangePassword = (e) => {
    setChangePassword(e.target.checked);
  };

  return (
    <PageLayout className="container">
      <Spin spinning={loading}>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={8} md={5}>
              <div style={{ textAlign: 'center' }}>
                <Spin spinning={imageloading}>
                  <div className="avatar-wrapper">
                    <Avatar
                      src={profileImageUrl}
                      size={180}
                      icon={<UserOutlined />}
                      className="profile-avatar"
                      style={{ 
                        objectFit: 'contain',
                       }}
                    />
                    <Upload {...uploadProps}>
                      <div className="upload-overlay">
                        <CameraOutlined style={{ color: 'white', fontSize: '20px' }} />
                      </div>
                    </Upload>
                  </div>
                </Spin>
                <Title level={4} style={{ marginTop: 16, marginBottom: 4 }}>
                  {form.getFieldValue('name')} {form.getFieldValue('last_name')}
                </Title>
                <Text type="secondary">
                  {form.getFieldValue('email')}
                </Text>
                
              </div>
          </Col>
          
          <Col xs={24} sm={16} md={19}>
            <Card 
              title="Edit Profile Information"
            >
              <ProfileForm
                form={form}
                handleFinish={handleFinish}
                btnLoading={btnLoading}
                changePassword={changePassword}
                toggleChangePassword={toggleChangePassword}
              />
            </Card>
          </Col>
        </Row>
      </Spin>
    </PageLayout>
  );
};

export default ProfilePage;
