import React, {useState} from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import './index.css'; 
import { Link } from 'react-router';
import { loginStart, loginSuccess, loginFailure } from '../../redux/userSlice';
import auth from '../../services/auth';
import PageLayout from '../../components/layout/Layout';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    dispatch(loginStart());

    try {
      const response = await auth.login(values);
      setLoading(false);
      if (response.data.is_locked) {
        message.error('Your account is locked. Please contact support.');
        dispatch(loginFailure());
        return;
      }
      const token = response.data.token;
      localStorage.setItem('token', token);
      const previousUrl = location?.state?.prevUrl;
      const dashboardUrl = '/dashboard';
      navigate(previousUrl ? previousUrl : dashboardUrl);
      dispatch(loginSuccess(response.data));
      message.success('Login successful');
    } catch (error) {
      if (error.response && error.response.status === 422) {
        message.error('Invalid credentials. Please check your username and password.');
      } else {
        message.error('Login failed. Please try again later.');
      }
      setLoading(false);
      dispatch(loginFailure());
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-header">
          <h1 className="login-title">Login</h1>
          <p className="login-subtitle">Welcome back! Please enter your details</p>
        </div>
        
        <Form
          name="login_form"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <div className="form-group">
            <label>Username</label>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
              ]}
            >
              <Input
                prefix={<UserOutlined className="input-icon" />}
                placeholder="Type your username"
              />
            </Form.Item>
          </div>

          <div className="form-group">
            <label>Password</label>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="input-icon" />}
                placeholder="Type your password"
              />
            </Form.Item>
          </div>
          
          <Form.Item className="remember-me-container">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>
          
          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              className="login-button" 
              loading={loading}
            >
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
