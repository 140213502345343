import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import { UserOutlined, MailOutlined, PhoneOutlined, HomeOutlined, IdcardOutlined, TagOutlined, FileTextOutlined, LockOutlined, CheckOutlined } from '@ant-design/icons';

const ProfileForm = ({ form, handleFinish, btnLoading }) => {
  const [changePassword, setChangePassword] = useState(false);

  const toggleChangePassword = () => {
    setChangePassword(!changePassword);
  };

  return (
    <Form
      form={form}
      name="edit_profile_form"
      onFinish={handleFinish}
      layout="vertical"
    >
      <>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="First Name"
              rules={[{ required: true, message: 'Please enter your first name!' }]}
            >
              <Input placeholder="Enter your first name" prefix={<UserOutlined />} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[{ required: true, message: 'Please enter your last name!' }]}
            >
              <Input placeholder="Enter your last name" prefix={<UserOutlined />} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'Please enter your email!' },
                { type: 'email', message: 'Enter a valid email address!' },
              ]}
            >
              <Input placeholder="Enter your email" prefix={<MailOutlined />} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              label="Phone Number"
              rules={[
                { required: true, message: 'Please enter your phone number!' },
                {
                  message: 'Enter a valid phone number!',
                },
              ]}
            >
              <Input placeholder="Enter your phone number" prefix={<PhoneOutlined />} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: 'Please enter your address!' }]}
            >
              <Input placeholder="Enter your address" prefix={<HomeOutlined />} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="agency_name"
              label="Agency Name"
              rules={[{ required: true, message: 'Please enter your agency name!' }]}
            >
              <Input placeholder="Enter your agency name" prefix={<IdcardOutlined />} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="license_number"
              label="License Number"
              rules={[{ required: true, message: 'Please enter your license number!' }]}
            >
              <Input placeholder="Enter your license number" prefix={<IdcardOutlined />} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="coversheet_tagline"
              label="Coversheet Tagline"
              rules={[{ required: true, message: 'Please enter your coversheet tagline!' }]}
            >
              <Input placeholder="Enter your coversheet tagline" prefix={<TagOutlined />} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          
          <Col span={24}>
            <Form.Item
              name="coversheet_products"
              label="Coversheet Products"
              rules={[{ required: true, message: 'Please enter your coversheet products!' }]}
            >
              <Input.TextArea rows={4} placeholder="Enter your coversheet products" prefix={<FileTextOutlined />} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="coversheet_disclosure"
              label="Coversheet Disclosure"
              rules={[{ required: true, message: 'Please enter your coversheet disclosure!' }]}
            >
              <Input.TextArea rows={6} placeholder="Enter your coversheet disclosure" prefix={<FileTextOutlined />} />
            </Form.Item>
          </Col>
        </Row>
        
        <Form.Item>
          <Checkbox onChange={toggleChangePassword}>
            Change Password
          </Checkbox>
        </Form.Item>
        {changePassword && (
          <>
            <Form.Item
              name="password"
              label="New Password"
              rules={[
                { required: true, message: 'Please enter your new password!' },
                { min: 6, message: 'Password must be at least 6 characters!' },
              ]}
            >
              <Input.Password placeholder="Enter new password" prefix={<LockOutlined />} />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Please confirm your new password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('The passwords do not match!')
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm new password" prefix={<CheckOutlined />} />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" className="edit-profile-button" loading={btnLoading}>
            Save Changes
          </Button>
        </Form.Item>
      </>
    </Form>
  );
};

export default ProfileForm;
